/* eslint-disable no-unused-vars */
import React, {useState, useCallback, useContext, useEffect} from 'react'
import { useForm } from 'react-hook-form';
import FormInputError from '../UI/form/FormInputError';
import Authcontext from '../store/Authcontext';
import {useNavigate} from "react-router-dom";
import { Alert,TextInput,Label,Button,Spinner } from 'flowbite-react';
import {HiUserCircle,HiInformationCircle} from 'react-icons/hi';
import { BsPatchCheckFill } from 'react-icons/bs';
import '../Styles/AddCV.css';
import '../UI/components/Upload/CVUpload.css';
import {useDropzone} from 'react-dropzone';

export default function AddCV() {
    const authcontext = useContext(Authcontext);
    const token = localStorage.getItem('token') || authcontext.token;
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [ successMessage, setSuccessMessage ] = useState([]);
    const { register, handleSubmit, formState } = useForm();
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const {getRootProps, getInputProps} = useDropzone({
        accept: '.pdf',
        onDrop: (acceptedFiles) => {
            if(acceptedFiles[0].size > 5000000){
                setError(true);
                setErrorMessage("File size is too big");
                return;
            }else{
                setError(false);
            }
            setFile(acceptedFiles[0]);
        }
    })
    const [errorMessage, setErrorMessage] = useState([]); 

    const handleSubmitForm = async (data) => {
        try {
            const formData = new FormData();
            formData.append('CVFile', file);
            formData.append('Name', data.cvTitle);
    
            const response = await fetch('https://jobhunter-api.secare.io/api/v1/cvs/createCV', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });
            const responseData = await response.json();
    
            if(!response.ok){
                throw new Error(responseData.message);
            }else{
                setSuccess(true);
                setSuccessMessage(responseData.message);
            }
        } catch (error) {
            setError(true);
            setErrorMessage(error.message);
        }
    } 
    

    useEffect(() => {
        if(token === null){
            navigate('/login');
        }
    }, [navigate, token])


  return (

    <div id="big">
        {error && <Alert id="alert" color="failure" icon={HiInformationCircle}>
            {error && errorMessage}
        </Alert>
        }
        {success && <Alert id="alert" color="success" icon={BsPatchCheckFill}>
            {successMessage}
        </Alert>
        }
    <form onSubmit={handleSubmit(handleSubmitForm)}>
    <div id="cv-info-title">
                <div className='mb-2'>
                        <Label className='text-2xl' style={{
                            color: "white",
                            fontWeight: "bold",
                        }}>CV Title :</Label>
                </div>
        <TextInput
            className='mb-3'
            id="title"
            name="title"
            label="Title"
            placeholder="CV Title..."
            {...register("cvTitle", { required: true })}
        />
        <div id="titleError">
        {formState.errors.cvTitle && file === null && <FormInputError>CV title is required</FormInputError>}
        </div>
            <div id="box" {...getRootProps()}>
                <input id="cv-file-upload" {...getInputProps()}/>
                <label id="label-file-upload" htmlFor="cv-file-upload">
                    <div>
                        {file ? <p>{file.name}</p> : <p>Drag and drop your CV here</p>}
                    </div>
                </label>
            </div>
        <Button type="submit" className="mb-5" style={{
            width: '50%',
            marginLeft: '30%',
        }}>
                Submit
        </Button>
        <hr id="underline" />
        <div id="vl" />
    </div>
    </form>
    </div>
  )
}
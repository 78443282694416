
import React from "react";

const CVHeader = (props) => {
    return (
            <div className="flex flex-col max-w-xs line-clamp-2" style={{
                height: "100%",
                padding: "20px 10px",
                paddingRight: "4px",
                fontWeight: "bold",
                fontFamily: "sans-serif",
                fontSize: "1.2rem",
                textAlign: "Left",
                float: "left",
            }}>
                <h1>{props.title}</h1>
        </div>
    );
};

export default CVHeader;
const CV = (props) => {
    return(
        <div className="flex bg-white flex-row justify-evenly mb-2" style={{
            width: '20cm',
            height: '4cm',
        }}>
            {props.children}
        </div>
    );
};

export default CV;
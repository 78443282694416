import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import { Alert, Spinner, Button,TextInput } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import { BsPatchCheckFill } from "react-icons/bs";
import { useForm } from "react-hook-form";
import FormInputError from '../../form/FormInputError';

const ResetPasswordForm = () => {
    const { register, handleSubmit, formState } = useForm();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [ successMessage, setSuccessMessage ] = useState([]);
    const navigate = useNavigate();
    const submitHandler = async (formData) => {
        try {
            const response = await fetch('https://jobhunter-api.secare.io/api/v1/user/forgetPassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const responseData = await response.json();

            if(!response.ok){
                throw new Error(responseData.message);
            }else{
                setSuccess(true);
                setSuccessMessage(responseData.message);
                setTimeout(() => {
                    navigate('/login');
                }, 3000);
            }
        } catch (error) {
            setError(error.message);
        }
    };
    return (
       <div className='justify-evenly flex flex-col'>
            {error && success === false && (
                <Alert color="failure" icon={HiInformationCircle} rounded={true} onDismiss={true} withBorderAccent={true}>
                    {error}
                </Alert>)
            }
            {success === true && (
                <Alert
                    color="success"
                    icon={BsPatchCheckFill}
                >
                    {successMessage}
                </Alert>)
            }
            <h5 className="text-lg justify-center text-center text-white py-9" style={{
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                fontSize: '30px',
            }}>Submit your Email address</h5>
            <form className="flex flex-col p-10 gap-5 bg-gray-800 h-fit" style={{borderRadius: "40px"}} onSubmit={handleSubmit(submitHandler)}>
                            <div className="block">
                                    <label className='text-white'>Email</label>
                            </div>
                        <TextInput
                                        id="email"
                                        type="email"
                                        placeholder="Email"
                                        {...register("email", { required: true })}
                        />
                        {formState.errors.email && <FormInputError>Email address is required</FormInputError>}
                <Button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {!formState.isSubmitting && success === false && <span>Send recovery Email</span>}
                    {formState.isSubmitting && <><Spinner color="white" /><span>Sending</span></>}
                    {formState.isSubmitSuccessful && success === true && <span>Success!</span>} 
                </Button>
            </form>
       </div>
    );
};

export default ResetPasswordForm
import React, {useContext,useState,useEffect} from 'react';
import { Tabs,TextInput,Label,Button,Toast } from 'flowbite-react';
import {HiUserCircle} from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import FormInputError from '../UI/form/FormInputError';
import Authcontext from '../store/Authcontext';
import { RiLockPasswordFill } from 'react-icons/ri';

const Myaccount = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [ successMessage, setSuccessMessage ] = useState([]);
    const [user, setUser] = useState([]);
    const authcontext = useContext(Authcontext);
    const token = localStorage.getItem('token') || authcontext.token;
    const { register, handleSubmit, formState } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, formState: formState2 } = useForm();
    const [ready, setReady] = useState(false);

    const submitHandler = async (formData) => {
        try{
            const response = await fetch('https://jobhunter-api.secare.io/api/v1/user/updateAccount', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(formData)
            });
            const responseData = await response.json();
            if(!response.ok){
                if(responseData.error){
                throw new Error(responseData.error);
                }else{
                setSuccess(true)
                setReady(false)
                throw new Error(responseData.message);
                }
            }
            setSuccess(true);
            setSuccessMessage(responseData.message);
        }catch (err) {
            setError(err.message);
        }
    }
    const submitHandlerPassword = async (formData) => {
        try{
            const response = await fetch('https://jobhunter-api.secare.io/api/v1/user/changePassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(formData)
            });
            const responseData = await response.json();
            if(!response.ok){
                if(responseData.error){
                throw new Error(responseData.error);
                }else{
                throw new Error(responseData.message);
                }
            }
            setSuccess(true);
            setSuccessMessage(responseData.message);
        }catch (err) {
            setError(err.message);
        }
    }
    useEffect(() => {
        if(localStorage.getItem('token') === null){
            window.location.href = "/login";
        }
        const fetchUser = async () => {
            try{
                const response = await fetch('https://jobhunter-api.secare.io/api/v1/user/getCurrentUser', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                });
                const responseData = await response.json();
                if(!response.ok){
                    if(responseData.error){
                    throw new Error(responseData.error);
                    }else{
                    throw new Error(responseData.message);
                    }
                }
                setUser(responseData.user);
            }catch (err) {
                setError(err.message);
            }
        }
        fetchUser();
    }, [token]);

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        }} className="gap-2">
             {error !== null && (
                <Toast style={{
                    position: 'absolute',
                    marginLeft: '30cm',
                    marginBottom: '10cm',
                }}>
                    <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
                        ❌
                    </div>
                    <div className="ml-3 text-sm font-normal">
                        {error}
                    </div>
                    <Toast.Toggle />
                </Toast>
            )}
             {success === true && (
                <Toast style={{
                    position: 'absolute',
                    marginLeft: '30cm',
                    marginBottom: '10cm',
                }}>
                    <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                        ✅
                    </div>
                    <div className="ml-3 text-sm font-normal">
                        {successMessage}
                    </div>
                    <Toast.Toggle />
                </Toast>
            )}
            <div className="bg-white">
            <Tabs.Group>
                    <Tabs.Item 
                        title="Account"
                        icon={HiUserCircle}
                    >
                        <div className=" bg-white" style={{
                            display: "flex",
                            flexDirection: "column",
                            width : "100%",
                            padding: "40px",
                            gap: "20px"
                        }}>
                            <form onSubmit={handleSubmit(submitHandler)}>
                            <div className="flex flex-col bg-white" style={{
                                    width: "500px",
                                    padding: "20px",
                                    borderRadius: "10px",
                                    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
                                    paddingLeft: "20px",
                                    paddingRight: "20px"
                                }}>
                                    <div className="mb-5 block text-lg">
                                        <Label htmlFor="name">Name</Label>
                                    </div>
                                    <TextInput
                                        id="name"
                                        type="text"
                                        placeholder="Name"
                                        defaultValue={user.name}
                                        {...register("name", { required: false })}
                                        className="mb-5"
                                        onChange={() => {
                                            setReady(true);
                                        }}
                                    />
                                    {formState.errors.name && <FormInputError>New Name is required</FormInputError>}
                                    <div className="mb-5 block text-lg">
                                    <Label htmlFor="email">Email</Label>
                                    </div>
                                    <TextInput
                                        id="email"
                                        type="email"
                                        placeholder="Email"
                                        defaultValue={user.email}
                                        {...register("email", { required: false })}
                                        className="mb-5"
                                        onChange={() => {
                                            setReady(true);
                                        }}
                                    />
                                    {formState.errors.email && <FormInputError>New Email is required</FormInputError>}
                                    <Button
                                        type="submit"
                                        className="align-self-center"
                                    >
                                        {(ready === true && success !== true) ? 'Save' : 'Update'}
                                    </Button>
                                </div>
                                </form>
                        </div>
                    </Tabs.Item>
                    <Tabs.Item title="Password" icon={RiLockPasswordFill}>
                    <div className=" bg-white" style={{
                            display: "flex",
                            flexDirection: "column",
                            width : "100%",
                            padding: "40px",
                            gap: "20px"
                        }}>
                            <form onSubmit={handleSubmit2(submitHandlerPassword)}>
                            <div className="flex flex-col bg-white" style={{
                                    width: "500px",
                                    padding: "20px",
                                    borderRadius: "10px",
                                    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
                                    paddingLeft: "20px",
                                    paddingRight: "20px"
                                }}>
                                    <div className="mb-5 block">
                                        <Label htmlFor="password">Old Password</Label>
                                    </div>
                                    <TextInput
                                        label="Old Password"
                                        id="OldPassword"
                                        type="password"
                                        placeholder="Old Password"
                                        {...register2("OldPassword", { required: true })}
                                        className="mb-5"
                                    />
                                    {formState2.errors.OldPassword && <FormInputError>Old Password is required</FormInputError>}
                                    <div className="mb-5 block">
                                        <Label htmlFor="password">New Password</Label>
                                    </div>
                                    <TextInput
                                        label="New Password"
                                        id="NewPassword"
                                        type="password"
                                        placeholder="New Password"
                                        {...register2("NewPassword", { required: true })}
                                        className="mb-5"
                                    />
                                    {formState2.errors.NewPassword && <FormInputError>New Password is required</FormInputError>}
                                    <Button
                                        type="submit"
                                        className=""
                                        style={{
                                            width: "50%",
                                            marginLeft: "25%",
                                        }}
                                    >
                                        Update
                                    </Button>
                                </div>
                                </form>
                    </div>
                    </Tabs.Item>
            </Tabs.Group>
            </div>
        </div>
        

    );
        





};


export default Myaccount;
import React, {useEffect, useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import Authcontext from "../store/Authcontext";
import { Spinner, Alert } from "flowbite-react";
import { BsPatchCheckFill } from "react-icons/bs";


const Logout = () => {
    const [successMessage, setSuccessMessage] = useState([]);
    const authCtx = useContext(Authcontext);
    const jwt = authCtx.token;
    const navigate = useNavigate();
    useEffect(() => {
        authCtx.logout();
        localStorage.removeItem("token");
        setSuccessMessage("You are logged out Succesfully! You are going to be redirected to the home page.");
        setTimeout(() =>
        {navigate("/")}, 5000);
    }, [authCtx, navigate]);


    return (
        <div>
            {jwt === null && <><Alert className="mb-5" color="success" icon={BsPatchCheckFill}>
                {successMessage + "👋Bye Bye👋"}
            </Alert>
            <div className="text-center mb-7">
                <Spinner aria-label="Center-aligned" size="lg"/>
            </div> </>}
        </div>
    );
};

export default Logout;
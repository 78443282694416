import CVSummary from "./CVSummary";

const CVSList = (props) => {
    return (
    <div className="flex justify-evenly flex-wrap">
        {props.cvs.map((cv) => (
            <CVSummary key={cv._id} cv={cv} />
        ))}
    </div>
    );
};

export default CVSList;
import React, {useState} from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import TextInput from "../../form/Textinput";
import FormInputError from "../../form/FormInputError";
import { Alert, Button, Spinner } from "flowbite-react";

const SignUpForm = () => {
    const [ error, setError ] = useState(null);
    const { register, handleSubmit, formState } = useForm();
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);
    // const [ submitted, setSubmitted ] = useState(false);

    const submitHandler = async (formData) => {
        try{
            const response = await fetch('https://jobhunter-api.secare.io/api/v1/user/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const responseData = await response.json();

            if(!response.ok){
                throw new Error(responseData.message);
            }
            setSuccess(true);
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (err) {
            setError(err.message);
        }
    };
    return (
    <div>
        {error && success === false && <FormInputError>{error}</FormInputError>}
        {success && (
            <Alert 
                color="success"
            >
                Account created successfully!, You are going to be redirected to the login page.
            </Alert>
        )}       
        <h5 className="text-lg text-center text-white py-9" style={{
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            fontSize: '30px',
        }}>Please submit your account info</h5>
         
        <form className="flex flex-col p-10 gap-5 bg-gray-800 h-fit" onSubmit={handleSubmit(submitHandler)} style={
                    {
                        borderRadius: "40px"
                    }
                }>
                    <TextInput
                        label="Email"
                        type="email"
                        placeholder="Enter your email address..."
                        register={register}
                        validation={{ required: true }}
                    />
                    {formState.errors.email && (
                        setError("Please enter a valid email")
                    )}
                    <TextInput
                        label="Password"
                        type="password"
                        placeholder="Enter your password..."
                        register={register}
                        validation={{ required: true }}
                    />
                    {formState.errors.password && (
                        setError("Please enter a valid password")
                    )}
                    <TextInput
                        label="Name"
                        type="text"
                        placeholder="Enter your full name..."
                        register={register}
                        validation={{ required: true }}
                    />
                    {formState.errors.name && (
                        setError("Please enter a valid name")
                    )}
                <Button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 rounded bg-center" type="submit">
                    {!formState.isSubmitting && success === false && <span>Sign up</span>}
                    {formState.isSubmitting && <><Spinner color="white" /><span>Signing up...</span></>}
                    {formState.isSubmitSuccessful && success === true && <span>Success!</span>}
                </Button>
            </form>
            <p className="text-2x text-slate-50 font-bold text-center">Have an account already? <a href="/login" className="text-bold text-white underline hover:text-blue-300 italic">Login now</a></p>
        </div>
    );
};

export default SignUpForm;
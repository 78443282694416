
import { BrowserRouter,Route, Routes } from "react-router-dom";

import AuthProvider from "./store/AuthProvider";
import Layout from "./UI/Layout/layout";
import Home from "./pages/home";

import MyCvs from "./pages/mycvs";
import SigninPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import Logout from "./pages/logout";
import Myaccount from "./pages/Myaccount";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ResetPassword from "./pages/ResetPassword";
import AddCV from "./pages/AddCV";
import EditCV from "./pages/EditCV";
import Skillsmatchingpage from "./pages/Skillsmatchingpage";

const App = () => {
  return (
    // Auth Provider is a component which wraps all components in the app and provides the auth context to all components
    <AuthProvider>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/mycvs" element={<MyCvs />} />
            <Route path="/login" element={<SigninPage />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/myaccount" element={<Myaccount />} />
            <Route path="/reset-password" element={<ResetPasswordPage />}/>
            <Route path="/reset-password/:resetPassToken" element={<ResetPassword />}/>
            <Route path="/addcv" element={<AddCV />}/>
            <Route path="/cv/:cvId" element={<EditCV />}/>
            <Route path="/getmatch" element={<Skillsmatchingpage />}/>
          </Routes>
        </Layout>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;

import React, {useState, useEffect, useContext} from "react";
import CVSList from "../UI/components/CVS/CVSList"
import Authcontext from "../store/Authcontext";
import { useNavigate } from "react-router-dom";

const MyCvs = () => {
    const [cvs, setCvs] = useState([]);
    const authd = useContext(Authcontext);
    const jwt = authd.token || localStorage.getItem("token");
    const navigate = useNavigate();

    useEffect(() => {
    const fetchCVs = async () => {
        try {
            const response = await fetch("https://jobhunter-api.secare.io/api/v1/cvs/usercvs", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + jwt
                }
            });
            const responseData = await response.json();
            if(!response.ok){
                throw new Error(responseData.error);
            }
            setCvs(responseData.ApplicantCVs);
        } catch (err) {
            setTimeout(() => {
                return (
                    <div className="flex justify-center py-10">
                        <h1 className="text-3xl font-bold">{err.message}</h1>
                    </div>
                );
            }, 3000);
        }
    };
    fetchCVs();
}, [jwt]);

    if(authd.token === null && localStorage.getItem("token") === null){
        navigate("/login");
    }
    if(cvs.length === 0){
        return (
            <div className="flex justify-center py-10">
                <h1 className="text-3xl font-bold" style={{
                    color: "white"
                }}>You have no CVs yet!</h1>
            </div>
        );
    }
    return (
        <CVSList cvs={cvs} />
    );
};

export default MyCvs;
import React, {useState} from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Button, TextInput, Spinner } from 'flowbite-react';
import { useForm } from "react-hook-form";
import { HiInformationCircle } from 'react-icons/hi';
import { BsPatchCheckFill } from 'react-icons/bs';
import FormInputError from '../UI/form/FormInputError';
import { Navigate } from 'react-router-dom';

const ResetPassword = () => {
    const { register, handleSubmit, formState } = useForm();
    let resetPassToken = useParams().resetPassToken;
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [ successMessage, setSuccessMessage ] = useState([]);

    const submitHandler = async (formData) => {
        try {
            const response = await fetch(`https://jobhunter-api.secare.io/api/v1/user/passwordReset/${resetPassToken}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const responseData = await response.json();

            if(!response.ok){
                throw new Error(responseData.message);
            }else{
                setSuccess(true);
                setSuccessMessage(responseData.message);
                setTimeout(() => {
                    return <Navigate to='/login' />;
                }, 3000);
            }
        } catch (error) {
           setError(error.message); 
        }
    };
    return (
        <div className='justify-evenly flex flex-col'>
            {error && success === false && (
                <Alert color="failure" icon={HiInformationCircle} rounded={true} onDismiss={true} withBorderAccent={true}>
                    {error}
                </Alert>)
            }
            {success === true && (
                <Alert
                    color="success"
                    icon={BsPatchCheckFill}
                >
                    {successMessage}
                </Alert>)
            }
            <h5 className="text-lg justify-center text-center text-white py-9" style={{
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                fontSize: '30px',
            }}>Submit your new password</h5>
            <form className="flex flex-col p-10 gap-5 bg-gray-800 h-fit" style={{borderRadius: "40px"}} onSubmit={handleSubmit(submitHandler)}>
                <div className="block">
                    <label className="text-white">New Password</label>
                    <TextInput
                        name = "password"
                        type=  "password"
                        placeholder="Enter your new password"
                        {...register("newPassword", { required: true, minLength: 6 })}
                    />
                    {formState.errors.newPassword && <FormInputError>New Password is required</FormInputError>}
                </div>
            <Button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {!formState.isSubmitting && success === false && <span>Submit New Passowrd</span>}
                    {formState.isSubmitting && <><Spinner color="white" /><span>Sending</span></>}
                    {formState.isSubmitSuccessful && success === true && <span>Success!</span>} 
            </Button>
            </form>
        </div>
    );
};

export default ResetPassword
import { Button } from "flowbite-react";
import { Typewriter } from 'react-simple-typewriter'

const Home = () => {
    const token = localStorage.getItem('token');
    const sentences = [
        "Unlock your potential with JobHunter.",
        "Discover the perfect job match with JobHunter.",
        "Let JobHunter guide you towards career success.",
        "Elevate your job search with JobHunter's advanced features.",
        "JobHunter: Your trusted partner in finding your dream job.",
        "Empower your job applications with JobHunter's skill extraction.",
        "Experience a seamless job hunting journey with JobHunter.",
    ];
    return (
    <div className="py-12" style={{
        cursor: "auto",
        width: "100%",
    }}>
        <h5 style={{
            fontFamily: 'Cerebri Sans',
            fontWeight: 400,
            letterSpacing: "3px",
            fontSize: '20px',
            color: 'white',
            marginBottom: '2cm',
            width: '50%',
        }}>
            Free Resume Checker with the power of Machine Learning Trained Models..
        </h5>
        <div className="text-white" style={{
            fontFamily: 'Cerebri Sans',
            marginTop: '1cm',
            marginBottom: '1cm',
            fontWeight: 400,
            fontSize: '30px',
        }}>
            <Typewriter
            loop
            cursor
            cursorStyle="|"
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
            words={sentences}
        />
        </div>
        <h1 style={{
            color: 'white',
            fontFamily: 'Cerebri Sans',
            fontWeight: "bold",
            fontSize: '20px',
            marginBottom: '0.9cm',
        }}>Unleash Your Skills, Land Your Dream Job</h1>
        <div style={{
            width: '50%',
        }}>
            <p style={{
                color: 'white',
                letterSpacing: "1px",
                fontFamily: 'Cerebri Sans',
                fontWeight: 400,
                fontSize: '20px',
            }}>
            Say goodbye to the frustrating job hunt and hello to JobHunter - your ultimate career companion. Our cutting-edge platform revolutionizes the way you apply for jobs, effortlessly extracting and highlighting the skills that employers crave. No more guesswork. No more missed opportunities. With JobHunter, you'll confidently showcase your expertise and take control of your career trajectory. Seamlessly navigate the job market, discover the perfect match, and land your dream job. It's time to let your skills shine with JobHunter.
            </p>
        </div>
       {token === null ?  <div style={{
            width: '50%',
        }}>
            <Button
                className="mt-5"
                style={{
                    backgroundColor: '#10B981',
                    width: '50%',
                    marginLeft: '1%',
                }}
                href="/signup"
            >
                Get Started By Signing Up
            </Button>

        </div> : <div style={{
            width: '50%',
        }}>
            <Button
                className="mt-5 hover:text-white"
                style={{
                    backgroundColor: '#10B981',
                    width: '50%',
                    marginLeft: '25%',
                    hover: 'bg-green-500',
                }}
                href="/addcv"
            >
                Start by uploading your CV
            </Button>
        </div>
        }
        <div style={{
            position: "absolute",
            marginLeft: "20cm",
            marginTop: "-13cm",
            height: "5cm"
        }}>
        <img alt="JobHunter" src="https://i.ibb.co/P1LL5Jc/preview.png" />
        </div>
    </div>
    );
};

export default Home;
import CVAction from "./CVAction";
import CVBody from "./CVBody";
import CVHeader from "./CVHeader";
import CV from "./CV";
import { Button } from "flowbite-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const CVSummary = (props) => {
    const navigate = useNavigate();
    const btnOnClickHandler = () => {
        navigate(`/cv/${props.cv._id}`);
    };

    return (
        // CV component is a wrapper for CVHeader, CVBody and CVAction components
        // in the CVHeader component the title prop which is the name of the CV
        // in the CVBody component the body prop is passed to it which is the CV body itself
        // in the CVAction component the button component is integrated into it which is the edit button 
        // Edit button is a child of the CVAction component in this case and sent as props.children
            <CV>
                <CVHeader title={props.cv.name} />
                <CVBody body={props.cv.CV}/>
                <CVAction>
                    <Button onClick={btnOnClickHandler} style={{
                        backgroundColor: "#11009E",
                        position: "absolute",
                    }}>
                        Edit
                    </Button>
                </CVAction>
            </CV>
    );
};

export default CVSummary;
import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import {Toast, TextInput, Button, Spinner} from 'flowbite-react'
import { useForm } from 'react-hook-form'
import FormInputError from '../UI/form/FormInputError'
import { useNavigate } from 'react-router-dom'
import TextareaAutosize from 'react-textarea-autosize';
import jobTitles from '../UI/Jobtitles.json'
function EditCV() {
  const navigate = useNavigate()
  const [cv, setCv] = useState({})
  const params = useParams()
  const cvId = params.cvId
  const [error , setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState([])
  const { register, handleSubmit, formState } = useForm()
  const [ready, setReady] = useState(false)
  const [interestedJob, setInterestedJob] = useState([])

  const handleInterestedJob = async (data) => {
    try {
        const response = await fetch(`https://jobhunter-api.secare.io/api/v1/cvs/${cvId}/addInterested`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                jobTitle: data.jobTitle,
            })
        })
        const responseData = await response.json()
        if(!response.ok){
            throw new Error(responseData.message)
        }else{
            setSuccess(true)
            setSuccessMessage(responseData.message)
        }
    } catch (error) {
        setError(error.message)
        setSuccess(false)
    }
};

  const handleCVdelete = async () => {
    try {
        const response = await fetch(`https://jobhunter-api.secare.io/api/v1/cvs/${cvId}`, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        })
        const responseData = await response.json()
        
        if(!response.ok){
            throw new Error(responseData)
        }else{
            setSuccess(true)
            setSuccessMessage(responseData.message)
        }
    } catch (error) {
        setError(error.message)
        setSuccess(false)
    }
    }



  const handleSubmitForm = async (data) => {
    try {
        const newTitle = data.cvTitle;
        const response = await fetch(`https://jobhunter-api.secare.io/api/v1/cvs/updateCVName`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                cvId: cvId,
                cvName: newTitle
            })
        })
        const responseData = await response.json()
        if(!response.ok){
            throw new Error(responseData.message)
        }else{
            setSuccess(true)
            setReady(false)
            setSuccessMessage(responseData.message)
        }
    } catch (error) {
        setError(error.message)
        setSuccess(false)   
    }
}

  useEffect(() => {
    if(localStorage.getItem('token') === null){
        navigate('/login')
    }
    const myAbortController = new AbortController();
    const fetchCv = async () => {
        try {
        const response = await fetch(`https://jobhunter-api.secare.io/api/v1/cvs/${cvId}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }, {signal: myAbortController.signal})
        const responseData = await response.json()
        if(!response.ok){
            throw new Error("CV " + responseData.error || responseData.message)
        }
        setCv(responseData.CV)
        setIsLoaded(true)
        } catch (error) {
            setError(error.message)
        }   
    }
    fetchCv()
    setInterestedJob(jobTitles.titles)
  }, [cvId, navigate])
  return (
    <div className='justify-evenly flex flex-col'>
    {error !== null && success === false && (
        <Toast style={{
            position: 'absolute',
            marginLeft: '30cm',
            marginBottom: '60cm',
        }}>
        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
            ❌
        </div>
        <div className="ml-3 text-sm font-normal">
            {error}
        </div>
        <Toast.Toggle />
     </Toast>
    )}
    {success === true && (
        <Toast style={{
            position: 'absolute',
            marginLeft: '30cm',
            marginBottom: '60cm',
        }}>
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                ✅
            </div>
            <div className="ml-3 text-sm font-normal">
                {successMessage}
            </div>
            <Toast.Toggle />
        </Toast>
    )}
    {isLoaded === false && (
        <div className="text-center">
            <Spinner size="xl" aria-label="Extra large spinner example"/>
        </div>)
    }
    {isLoaded === true && (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="flex flex-col justify-center items-center" style={{
                paddingRight: '20cm',
            }}>
                <div style={{
                    paddingRight: "8.5cm"
                }}>
                <label className='mb-3 text-lg' style={{
                    color: "white"
                }}>CV title</label>
                <TextInput
                    defaultValue={cv.name}
                    {...register("cvTitle", { required: true })}
                    onChange={() => {
                        setReady(true)
                    }}
                />
                {formState.errors.cvTitle && <FormInputError>CV title is required</FormInputError>}
                </div>
                <div style={{
                    position: "absolute",
                    top: "4.79cm",
                    left: "2.6cm",

                }}>
                <label className='mb-3 text-lg' style={{
                    position: "absolute",
                    bottom: "0.8cm",
                    color: "white"
                }}>Interested job</label>
                <select
                    defaultValue={cv.interestedJobTitle}
                    style={{
                        borderRadius: '15px',
                    }}
                    >
                    <option value="">Select interested job title</option>
                    {interestedJob.map((job, index) => {
                        return (
                            <option key={index} value={job}>{job}</option>
                        )
                    })}
                </select>
                <Button
                    type="submit"
                    style={{
                        backgroundColor: '#11009E',
                        bottom: '1px',
                        left: '6.5cm',
                        position: 'absolute',
                    }}
                    onClick={handleSubmit(handleInterestedJob)}
                >
                    Add
                </Button>
                </div>
                <Button
                    className='mt-3'
                    type="submit"
                    style={{
                        backgroundColor: '#11009E',
                        bottom: '1.3cm',
                        position: 'relative',
                    }}
                >
                    {ready === true ? 'Save' : 'Edit'}
                </Button>
                <div style={{
                     bottom: '2.3cm',
                     position: 'relative',
                     right: '-2.3cm',
                }}>
                <Button
                    style={{
                        backgroundColor: '#11009E',
                        position: 'relative',
                    }}
                    onClick={async () => {
                        let response = prompt('Are you sure you want to delete this CV? Type "yes" to confirm.', 'yes')
                        if(response === 'yes'){
                            handleCVdelete()
                            setTimeout(() => {
                                navigate('/mycvs')
                            }, 2000)
                        }
                    }}
                >
                    Delete CV
                </Button>
                </div>
                <p className="text-lg" style={{
                    marginLeft: '-11cm',
                    color: 'white',
                }}>CV Body:</p>
                <TextareaAutosize
                    cacheMeasurements
                    value={cv.CV.toString().trim('\r\n')}
                    disabled={true}
                    style={{
                        width: '20cm',
                        height: '30cm',
                        marginBottom: '10cm',
                        padding: '1cm',
                        border: '1px solid #D1D5DB',
                        borderRadius: '0.375rem',
                        backgroundColor: '#F9FAFB',
                        marginLeft: '7.5cm',
                        resize: 'none',
                    }}
                />
            </div>
        </form>)
    }
    </div>
  )
}

export default EditCV
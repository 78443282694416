/* eslint-disable no-unused-vars */
import React, {useState,useEffect} from 'react'
import TextareaAutosize from 'react-textarea-autosize';
import { Spinner, Toast, Button, Tooltip } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FormInputError from '../UI/form/FormInputError';
import "../Styles/Skills.css"
const Skillsmatchingpage = () => {
    const { register, handleSubmit, formState } = useForm()
    const navigate = useNavigate()
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [success, setSuccess] = useState(null)
    const [cvs, setCvs] = useState([])
    const [isReceived, setIsReceived] = useState(false)
    const [matchingSkills, setMatchingSkills] = useState([])
    const [isReceivedSkills, setIsReceivedSkills] = useState(false)

    const handleGetMatch = async (data) => {
        try {
            setIsReceived(false)
            const response = await fetch('https://jobhunter-api.secare.io/api/v1/cvs/checkCV', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    JobDescription: data.JobDescription,
                    CVId: data.ChoosenCV
                })
            })
            const responseData = await response.json()
            if(!response.ok){
                throw new Error(responseData)
            }else{
                setIsReceived(true)
                setSuccess("Report Received")
                setIsReceivedSkills(true)
                setMatchingSkills(responseData)
            }
        } catch (error) {
            setError(error.message)
        }
    }
    useEffect(() => {
        const fetchCvs = async () => {
            try {
                const response = await fetch('https://jobhunter-api.secare.io/api/v1/cvs/usercvs', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
                const responseData = await response.json()
                if(!response.ok){
                    throw new Error(responseData)
                }else{
                    setCvs(responseData.ApplicantCVs)
                    setIsLoaded(true)
                }
            } catch (error) {
                setError(error.message || error.error)
                setIsLoaded(true)
            }
        }
        fetchCvs()
    }, [navigate])

    if(localStorage.getItem('token') === null){
        navigate('/login')
    }

  return (
    <div className='justify-evenly flex flex-col'>
    {error !== null && (
        <div style={{
            marginLeft: '21.5cm',
            position: 'absolute',
        }}>
        <Toast>
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
                ❌
            </div>
            <div className="ml-3 text-lg font-normal">
                {error}
            </div>
            <Toast.Toggle />
        </Toast>
        </div>
    )}
    {success !== null && (
        <div style={{
            marginLeft: '21.5cm',
            position: 'absolute',
        }}>
        <Toast>
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                ✅
            </div>
            <div className="ml-3 text-lg font-normal">
                {success}
            </div>
            <Toast.Toggle />
        </Toast>
        </div>
    )}
    {isLoaded === false && (
        <div className="text-center">
            <Spinner size="xl" aria-label="Extra large spinner example"/>
        </div>)
    }
    {isLoaded === true && (
        <form onSubmit={handleSubmit(handleGetMatch)} className='mt-5'>
            <div style={{
                marginRight: '19.5cm',
                }}>
                <Button type="submit" className="mt-2"
                style={{
                    position: 'absolute',
                    marginRight: '13cm',
                    width: "5cm",
                    marginTop: '-0.19px',
                    background: isReceived === true ? "green" : "#4942E4"
                }}>Get Match</Button>
            </div>
            <div style={{
                position: 'absolute',
                left: '11cm',
                top: '2.4cm',
                width: '6cm',
                marginLeft: "-10cm"
            }}>
                <label className="block text-lg text-white">
                    Choose CV
                </label>
                <select name="ChoosenCV" className="mt-2 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" 
                        {...register("ChoosenCV", { required: true })}
                    >
                    <option value="">Select Option</option>
                    {cvs.map((cv) => (
                        <option key={cv._id} value={cv._id}>{cv.name}</option>
                    ))}
                </select>
                {formState.errors.ChoosenCV && <FormInputError>CV is required</FormInputError>}
                <label className="block text-lg text-white mt-5">
                    Job Description
                </label>
                <div style={{
                    position: 'absolute',
                }}>
                    <TextareaAutosize minRows={8} maxRows={8}  {...register("JobDescription", { required: true })} className="mt-1 pl-3 pr-10 py-2 text-base sm:text-sm rounded-md" style={{
                        width: '30cm',
                        resize: 'none',
                    }}/>
                </div>
                <div style={{
                    position: 'absolute',
                    right: '4.2cm',
                    width: '6cm'
                }}>
                {formState.errors.JobDescription && <FormInputError>Job Description is required</FormInputError>}
                </div>
            </div>
        </form>
    )}
    {isReceived === true && (
        <div>
                <div>
                    <hr style={{
                        position: 'absolute',
                        marginLeft: '-11cm',
                        width: '45cm',
                        marginTop: '7cm',
                    }}/>
                    <p style={{
                        position: 'absolute',
                        marginTop: '7.3cm',
                        marginLeft: '7.5cm',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: 'white',
                    }}>
                        Skills Matching Report
                    </p>
                </div>
                <div>
                    <hr style={{
                        position: 'absolute',
                        marginLeft: '-11cm',
                        width: '45cm',
                        marginTop: '7cm',
                    }}/>
                </div>
                <p style={{
                    position: 'absolute',
                    marginTop: '7.3cm',
                    marginLeft: '-7.5cm',
                    fontWeight: 'bold',
                    color: 'white',
                }}>
                    Matching Percentage:
                </p>
                <p style={{
                    position: 'absolute',
                    marginTop: '7.3cm',
                    marginLeft: '-3.3cm',
                    color: matchingSkills.MatchingPercentage > 50 ? '#26FF1A' : 'white',
                }}>
                    {matchingSkills.MatchingPercentage}%
                </p>
                <hr style={{
                position: 'absolute',
                marginLeft: '-11cm',
                width: '45cm',
                marginTop: '8.1cm',
                }}/>
                <div style={{
                position: 'absolute',
                marginTop: '9cm',
                marginLeft: '-7.5cm',
                fontSize: '20px',
                }}>
                    <p style={{
                        fontWeight: 'bold',
                        color: 'white',
                    }}>
                        Technical Skills:
                    </p>
                    {Object.entries(matchingSkills.TechSkills).map(([key,value]) => 
                    <div>
                        <p style={{
                            color: value === 1 ? '#26FF1A' : 'white',
                            textTransform: 'capitalize',
                        }}>
                            {value === 0 ? <><Tooltip id="skill" content="This is a missed Skill, click here to get more courses for this skill"><a href={"https://www.udemy.com/courses/search/?lang=en&ratings=4.5&sort=relevance&src=ukw&q="+ key} target="_blank" rel="noreferrer">{key}: {value}</a></Tooltip></> : <>{key}: {value}</>}
                        </p>
                    </div> 
                    )}
                </div>
                <div style={{
                position: 'absolute',
                marginTop: '9cm',
                marginLeft: '7.7cm',
                fontSize: '20px',
                }}>
                    <p style={{
                        fontWeight: 'bold',
                        color: 'white',
                    }}>
                        Soft Skills
                    </p>
                    {Object.entries(matchingSkills.SoftSkills).map(([key,value]) => 
                    <div>
                        <p style={{
                            color: value === 1 ? '#26FF1A' : 'white',
                            textTransform: 'capitalize',
                        }}>
                            {value === 0 ? <><Tooltip id="skill" content="This is a missed Skill, click here to get more courses for this skill"><a href={"https://www.udemy.com/courses/search/?lang=en&ratings=4.5&sort=relevance&src=ukw&q="+ key} target="_blank" rel="noreferrer">{key}: {value}</a></Tooltip></> : <>{key}: {value}</>} 
                        </p>
                    </div> 
                    )}
                </div>
                <div style={{
                position: 'absolute',
                marginTop: '9cm',
                marginLeft: '21.7cm',
                fontSize: '20px',
                }}>
                    <p style={{
                        fontWeight: 'bold',
                        color: 'white',
                    }}>
                        Certifications
                    </p>
                    {Object.entries(matchingSkills.Certifications).map(([key,value]) => 
                    <div>
                        <p style={{
                            color: value === 1 ? '#26FF1A' : 'white',
                            textTransform: 'capitalize',
                        }}>
                             {value === 0 ? <><Tooltip id="skill" content="This is a missed Skill, click here to get more courses for this skill"><a href={"https://www.udemy.com/courses/search/?lang=en&ratings=4.5&sort=relevance&src=ukw&q="+ key} target="_blank" rel="noreferrer">{key}: {value}</a></Tooltip></> : <>{key}: {value}</>}
                        </p>
                    </div> 
                    )}
                </div>
        </div>
    )}
    </div>
  )
}

export default Skillsmatchingpage